import React from 'react';
import imageSets from './PropertyPhotosList';
import './stylesheets/home.css';

/**
 * Home component
 * 
 * The landing page component that welcomes users and provides an overview of the Blue Sails Lakeside Retreat.
 */
function Home() {
  return (
    <div className='h-cnt'>
      {/* Header section with a background image and welcome text */}
      <div className='hi-cnt'>
        <img src={imageSets['set_ls'][4]} alt='Lakeside View' />
        <div className='hi-txt-cnt'>
          <h1 className='h-txt txt-title'>
            Welcome to 
          </h1>
          <h2 className='h-txt'>
            Blue Sails Lakeside Retreat
          </h2>
        </div>
      </div>

      {/* About section with a description and images */}
      <div className='ab-img-cnt'>
        <h2 className='h-txt ab'>
          One of Prince Edward County's most unique and charming vacation rentals.
        </h2>
        <div className='ab-img'>
          <img src={imageSets['set_a'][2]} alt='Abstract view 2' />
          <img src={imageSets['set_a'][5]} alt='Abstract view 1' />
          <img src={imageSets['set_k'][1]} alt='Abstract view 3' />
          <img src={imageSets['set_o'][3]} alt='Abstract view 4' />
        </div>
      </div>

      {/* Section describing what the property offers */}
      <div className='wwo-cnt'>
        <div className='wwo-txt'>
          <h1>
            What We Offer
          </h1>
          <h2 className='h-txt'>
            This vacation home in Wellington, Prince Edward County, offers all the comforts of a retreat - great waterfront location on a very private 2 acres of property close to amenities, including local grocers, restaurants, and wineries.
          </h2>
        </div>
      </div>

      {/* Footer section with additional images */}
      <div className='hf-cnt'>
        <div className='hf-sml-img-cnt'>
          <img src={imageSets['set_a'][0]} alt='Abstract view 4' />
          <img src={imageSets['set_o'][0]} alt='Abstract view 1' />
        </div>
        <div className='hf-sml-txt-cnt'>
          <h1>
            Come enjoy the County
          </h1>
          <h2 className='h-txt'>
            Prince Edward County is home to Sandbanks white sand beaches, over 40 wineries, outstanding local artisans and world class restaurants, all within 2 hours from Toronto.
          </h2>
        </div>
        <div className='hf-lg-img-cnt'>
          <img src={imageSets['set_ls'][7]} alt='Abstract view 4' />
        </div>
      </div>
    </div>
  );
}

export default Home;
