import React from 'react';
import Slider from 'react-slick';
import './stylesheets/imgslider.css';

/**
 * ImageSlider component
 * 
 * A slider component that displays images in a carousel. Utilizes the 'react-slick' library for the slider functionality.
 * 
 * @param {Object} props - The properties passed to the component
 * @param {Array} props.images - An array of image URLs to be displayed in the slider
 */
const ImageSlider = ({ images }) => {
  // Configuration settings for the image slider
  const settings = {
    className: 'img-slider-cnt', // Custom class name for styling
    arrows: false, // Hide navigation arrows
    lazyLoad: 'ondemand', // Load images on demand
    dots: true, // Display navigation dots
    infinite: true, // Enable infinite scrolling
    speed: 500, // Transition speed in milliseconds
    autoplay: true, // Enable autoplay
    autoplaySpeed: 10000, // Autoplay speed in milliseconds (10 seconds)
  };

  return (
    <Slider {...settings}>
      {/* Map through the images array and create a slide for each image */}
      {images.map((image, index) => (
        <div key={index}>
          <img src={image} alt={`Slide ${index}`} className='active-img' />
        </div>
      ))}
    </Slider>
  );
};

export default ImageSlider;
