import React, { useEffect, useState } from 'react';
import { gapi } from 'gapi-script';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './stylesheets/calendar.css';

// Google API key for accessing the Calendar API
const API_KEY = 'AIzaSyDQfcoRDRtOpCld9JAomxtQH8clPLWMUlk';

// Google Calendar ID to fetch events from
const CALENDAR_ID = 'bluesailsrental@gmail.com'; 

// Discovery docs for Google Calendar API
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];

// Localizer for react-big-calendar using moment.js
const localizer = momentLocalizer(moment);

/**
 * AvailCalendar component
 * 
 * Fetches events from a Google Calendar and displays them in a calendar view.
 */
function AvailCalendar() {
  // State to store fetched events
  const [events, setEvents] = useState([]);

  // useEffect hook to initialize the Google API client and load events on component mount
  useEffect(() => {
    // Function to initialize the Google API client
    const initClient = () => {
      gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: DISCOVERY_DOCS,
      }).then(() => {
        // Load events after initializing the client
        loadEvents();
      }).catch(error => {
        console.error("Error initializing gapi client:", error);
      });
    };

    // Load the Google API client library and initialize it
    gapi.load('client', initClient);
  }, []);

  /**
   * Fetches events from the specified Google Calendar and updates the state.
   */
  const loadEvents = () => {
    gapi.client.calendar.events.list({
      'calendarId': CALENDAR_ID,
      'timeMin': (new Date()).toISOString(), // Fetch events starting from now
      'showDeleted': false, // Exclude deleted events
      'singleEvents': true, // Expand recurring events into instances
      'maxResults': 10, // Limit the number of results
      'orderBy': 'startTime' // Order events by their start time
    }).then(response => {
      // Map the API response to the format expected by react-big-calendar
      const events = response.result.items.map(event => ({
        title: event.summary, // Event title
        start: moment(event.start.dateTime || event.start.date).toDate(), // Event start time
        end: moment(event.end.dateTime || event.end.date).toDate(), // Event end time
      }));
      // Update the state with the fetched events
      setEvents(events);
    }).catch(error => {
      console.error("Error loading calendar events:", error);
    });
  };

  return (
    <div className='avcal-cnt'>
      <div className='cal-cnt' style={{ height: '600px', width: '75%', maxWidth: '900px', padding: '4%', color: 'black' }}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          views={['month', 'week']}
          style={{ height: 600, color: 'black' }}
        />
      </div>

      <div className='cal-txt'>
          <h1>
            Make A Rental Inquiry
          </h1>
          <h2 className='h-txt'>
            Please send us an email through the contact us page, to enquire about rental rates and availability confirmation for this 4 season property. Rates range from $2500-4500 a week depending on season. Weekends available in off season from $450 a night. Sleeps up to 6 adults and 2 children.
          </h2>
      </div>
    </div>
  );
}

export default AvailCalendar;
