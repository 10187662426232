import React, { useState } from 'react';
import './stylesheets/contact-form.css';
import sideimage from './images/Archive/Abstract/IMG_9520.JPG';
import emailjs from 'emailjs-com';

/**
 * ContactForm component
 * 
 * A form that allows users to submit inquiries. Sends form data via email using emailjs.
 */
function ContactForm() {
    // State to manage form data
    const [formData, setFormData] = useState({
        email: '',
        name: '',
        datein: '',
        dateout: '',
        phonenum: '',
        note: ''
    });

    /**
     * Handles input change events and updates the form data state.
     * 
     * @param {Object} e - The event object
     */
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    /**
     * Handles form submission. Sends form data via emailjs and resets the form.
     * 
     * @param {Object} e - The event object
     */
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        emailjs.send(
            'service_wqf1p0n', // Service ID
            'template_4axa8n6', // Template ID
            formData, // Form data
            'FfILBlshzmV6j62nh' // User ID
        ).then((result) => {
            console.log('Email sent:', result.text);
        }, (error) => {
            console.error('Email error:', error);
        });
        // Reset form data after submission
        setFormData({
            email: '',
            name: '',
            datein: '',
            dateout: '',
            phonenum: '',
            note: ''
        });
    };

    return (
        <div className='cf-cnt'>
            {/* Side image */}
            <img src={sideimage} alt='' className='side-img'/>

            {/* Vertical dividing line */}
            <div className='line'>

                {/* Contact form */}
                <form onSubmit={handleSubmit} className='cf'>
                    <div className='f-title'>Send us an Inquiry</div>

                    <div className='f-input-gp1'>
                        <div className='f-input-gp1-elemt'>
                            <label htmlFor="datein" >Check In</label>
                            <div className='f-input-logo'>
                                <svg className='f-input-logo' id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.89" height={22} width={20}><title>date</title><path d="M81.61,4.73C81.61,2.12,84.19,0,87.38,0s5.77,2.12,5.77,4.73V25.45c0,2.61-2.58,4.73-5.77,4.73s-5.77-2.12-5.77-4.73V4.73ZM66.11,105.66c-.8,0-.8-10.1,0-10.1H81.9c.8,0,.8,10.1,0,10.1ZM15.85,68.94c-.8,0-.8-10.1,0-10.1H31.64c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H56.77c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H81.9c.8,0,.8,10.1,0,10.1Zm25.14-10.1H107c.8,0,.8,10.1,0,10.1H91.25c-.8,0-.8-10.1,0-10.1ZM15.85,87.3c-.8,0-.8-10.1,0-10.1H31.64c.8,0,.8,10.1,0,10.1ZM41,87.3c-.8,0-.8-10.1,0-10.1H56.77c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H81.9c.8,0,.8,10.1,0,10.1Zm25.14,0c-.8,0-.8-10.1,0-10.1H107c.8,0,.8,10.1,0,10.1Zm-75.4,18.36c-.8,0-.8-10.1,0-10.1H31.64c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H56.77c.8,0,.8,10.1,0,10.1ZM29.61,4.73C29.61,2.12,32.19,0,35.38,0s5.77,2.12,5.77,4.73V25.45c0,2.61-2.58,4.73-5.77,4.73s-5.77-2.12-5.77-4.73V4.73ZM6.4,43.47H116.47v-22a3,3,0,0,0-.86-2.07,2.92,2.92,0,0,0-2.07-.86H103a3.2,3.2,0,0,1,0-6.4h10.55a9.36,9.36,0,0,1,9.33,9.33v92.09a9.36,9.36,0,0,1-9.33,9.33H9.33A9.36,9.36,0,0,1,0,113.55V21.47a9.36,9.36,0,0,1,9.33-9.33H20.6a3.2,3.2,0,1,1,0,6.4H9.33a3,3,0,0,0-2.07.86,2.92,2.92,0,0,0-.86,2.07v22Zm110.08,6.41H6.4v63.67a3,3,0,0,0,.86,2.07,2.92,2.92,0,0,0,2.07.86H113.55a3,3,0,0,0,2.07-.86,2.92,2.92,0,0,0,.86-2.07V49.88ZM50.43,18.54a3.2,3.2,0,0,1,0-6.4H71.92a3.2,3.2,0,1,1,0,6.4Z"/></svg>
                                <input
                                    className='f-input'
                                    type="text"
                                    id="datein"
                                    name="datein"
                                    value={formData.datein}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className='f-input-gp1-elemt'>
                            <label htmlFor="dateout">Check Out</label>
                            <div className='f-input-logo'>
                                <svg className='f-input-logo' id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.89" height={22} width={20}><title>date</title><path d="M81.61,4.73C81.61,2.12,84.19,0,87.38,0s5.77,2.12,5.77,4.73V25.45c0,2.61-2.58,4.73-5.77,4.73s-5.77-2.12-5.77-4.73V4.73ZM66.11,105.66c-.8,0-.8-10.1,0-10.1H81.9c.8,0,.8,10.1,0,10.1ZM15.85,68.94c-.8,0-.8-10.1,0-10.1H31.64c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H56.77c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H81.9c.8,0,.8,10.1,0,10.1Zm25.14-10.1H107c.8,0,.8,10.1,0,10.1H91.25c-.8,0-.8-10.1,0-10.1ZM15.85,87.3c-.8,0-.8-10.1,0-10.1H31.64c.8,0,.8,10.1,0,10.1ZM41,87.3c-.8,0-.8-10.1,0-10.1H56.77c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H81.9c.8,0,.8,10.1,0,10.1Zm25.14,0c-.8,0-.8-10.1,0-10.1H107c.8,0,.8,10.1,0,10.1Zm-75.4,18.36c-.8,0-.8-10.1,0-10.1H31.64c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H56.77c.8,0,.8,10.1,0,10.1ZM29.61,4.73C29.61,2.12,32.19,0,35.38,0s5.77,2.12,5.77,4.73V25.45c0,2.61-2.58,4.73-5.77,4.73s-5.77-2.12-5.77-4.73V4.73ZM6.4,43.47H116.47v-22a3,3,0,0,0-.86-2.07,2.92,2.92,0,0,0-2.07-.86H103a3.2,3.2,0,0,1,0-6.4h10.55a9.36,9.36,0,0,1,9.33,9.33v92.09a9.36,9.36,0,0,1-9.33,9.33H9.33A9.36,9.36,0,0,1,0,113.55V21.47a9.36,9.36,0,0,1,9.33-9.33H20.6a3.2,3.2,0,1,1,0,6.4H9.33a3,3,0,0,0-2.07.86,2.92,2.92,0,0,0-.86,2.07v22Zm110.08,6.41H6.4v63.67a3,3,0,0,0,.86,2.07,2.92,2.92,0,0,0,2.07.86H113.55a3,3,0,0,0,2.07-.86,2.92,2.92,0,0,0,.86-2.07V49.88ZM50.43,18.54a3.2,3.2,0,0,1,0-6.4H71.92a3.2,3.2,0,1,1,0,6.4Z"/></svg>
                                <input
                                    className='f-input'
                                    type="text"
                                    id="dateout"
                                    name="dateout"
                                    value={formData.dateout}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className='f-input-elemt'>
                        <label htmlFor="name">Your Name</label>
                        <div className='f-input-logo'>
                            <svg className='f-input-logo' xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 336.62" height={22} width={20}><path fill-rule="nonzero" d="M23.09 0h465.82C501.58 0 512 10.42 512 23.09v290.44c0 12.67-10.42 23.09-23.09 23.09H23.09C10.42 336.62 0 326.2 0 313.53V23.09C0 10.42 10.42 0 23.09 0zm220.86 180.16c-13.75 0-13.75-25.1 0-25.1h206.92c13.75 0 13.73 25.1 0 25.1H243.95zm153.16 51.29c-13.74 0-13.74-25.1 0-25.1h53.76c13.74 0 13.74 25.1 0 25.1h-53.76zm-153.16 0c-13.75 0-13.75-25.1 0-25.1h113.27c13.74 0 13.74 25.1 0 25.1H243.95zm.01 51.39c-13.76 0-13.75-25.09 0-25.09h207.13c13.74 0 13.74 25.09 0 25.09H243.96zM56.9 50.03h308.78c4.43 0 8.06 3.64 8.06 8.07v42.81c0 4.42-3.64 8.06-8.06 8.06H56.9c-4.43 0-8.07-3.62-8.07-8.06V58.1c0-4.44 3.63-8.07 8.07-8.07zm351.76 0h46.44c4.44 0 8.07 3.64 8.07 8.07v42.81c0 4.42-3.64 8.06-8.07 8.06h-46.44c-4.43 0-8.07-3.62-8.07-8.06V58.1c0-4.44 3.63-8.07 8.07-8.07zM52.72 282.84c-2.41 0-2.44-2.45-2.01-4.16 3.62-28.69 14.71-27.68 30.62-31.78 7.65-1.97 25.12-9.64 23.27-19.53-3.85-3.57-7.68-8.5-8.35-15.87l-.46.01c-1.07-.02-2.1-.26-3.06-.81-2.13-1.21-3.29-3.52-3.86-6.17-.55-2.61-.69-7.7 0-10.31.74-2.07 1.66-3.19 2.83-3.68l.03-.01c-.53-9.96 1.15-20.56-9.07-23.66 20.19-24.95 43.46-38.52 60.94-16.32 19.47 1.02 28.15 24.55 16.06 39.99h-.51c1.62.68 2.52 2.56 2.96 4.17.34 2.5.58 6.48-.14 9.82-.55 2.65-1.72 4.96-3.85 6.17-.96.55-1.99.79-3.06.81l-.47-.01c-.66 7.37-4.5 12.3-8.35 15.87-1.85 9.9 15.64 17.56 23.29 19.53 15.92 4.09 27.02 3.09 30.64 31.78.42 1.71.39 4.16-2.02 4.16H52.72zM488.91 16.43H23.09c-3.69 0-6.66 2.97-6.66 6.66v290.44c0 3.69 2.97 6.66 6.66 6.66h465.82c3.69 0 6.66-2.97 6.66-6.66V23.09c0-3.69-2.97-6.66-6.66-6.66z"/></svg>                        
                            <input
                                className='f-input'
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>

                    <div className='f-input-elemt'>
                        <label htmlFor="email">Email Address</label>
                        <div className='f-input-logo'>
                            <svg className='f-input-logo' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.879 88.855"  height={22} width={20}><g><path d="M7.048,0h108.784c1.939,0,3.701,0.794,4.977,2.069c1.277,1.277,2.07,3.042,2.07,4.979v74.759 c0,1.461-0.451,2.822-1.221,3.951c-0.141,0.365-0.361,0.705-0.662,0.994c-0.201,0.189-0.422,0.344-0.656,0.461 c-1.225,1.021-2.799,1.643-4.508,1.643H7.048c-1.937,0-3.701-0.793-4.979-2.07C0.794,85.51,0,83.748,0,81.807V7.048 c0-1.941,0.792-3.704,2.068-4.979C3.344,0.792,5.107,0,7.048,0L7.048,0z M5.406,78.842l38.124-38.22L5.406,9.538V78.842 L5.406,78.842z M47.729,44.045L8.424,83.449h105.701L76.563,44.051L64.18,54.602l0,0c-0.971,0.83-2.425,0.877-3.453,0.043 L47.729,44.045L47.729,44.045z M80.674,40.549l36.799,38.598V9.198L80.674,40.549L80.674,40.549z M8.867,5.406l53.521,43.639 l51.223-43.639H8.867L8.867,5.406z"/></g></svg>                        
                            <input
                                className='f-input'
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>

                    <div className='f-input-elemt'>
                        <label htmlFor="phonenum">Phone Num.</label>
                        <div className='f-input-logo'>
                            <svg className='f-input-logo' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.31 122.88" height={22} width={20}><g><path d="M12.47,0h46.36c3.43,0,6.55,1.4,8.81,3.66c2.26,2.26,3.66,5.38,3.66,8.81v97.94c0,3.43-1.4,6.55-3.66,8.81 c-2.26,2.26-5.38,3.66-8.81,3.66H12.47c-3.43,0-6.55-1.4-8.81-3.66C1.4,116.96,0,113.84,0,110.41V12.47c0-3.43,1.4-6.55,3.66-8.81 C5.92,1.4,9.04,0,12.47,0L12.47,0z M28.93,113c-0.83,0-1.5-0.67-1.5-1.5c0-0.83,0.67-1.5,1.5-1.5h13.72c0.83,0,1.5,0.67,1.5,1.5 c0,0.83-0.67,1.5-1.5,1.5H28.93L28.93,113z M27.65,7.16c1.31,0,2.38,1.07,2.38,2.38c0,1.31-1.07,2.38-2.38,2.38 c-1.31,0-2.38-1.07-2.38-2.38C25.27,8.23,26.34,7.16,27.65,7.16L27.65,7.16z M35.65,7.16c1.31,0,2.38,1.07,2.38,2.38 c0,1.31-1.07,2.38-2.38,2.38c-1.32,0-2.38-1.07-2.38-2.38C33.27,8.23,34.34,7.16,35.65,7.16L35.65,7.16z M43.65,7.16 c1.31,0,2.38,1.07,2.38,2.38c0,1.31-1.07,2.38-2.38,2.38c-1.32,0-2.38-1.07-2.38-2.38C41.27,8.23,42.34,7.16,43.65,7.16L43.65,7.16 z M3,99.29h65.31V19.21H3V99.29L3,99.29z M68.31,102.29H3v8.12c0,2.6,1.07,4.97,2.78,6.69c1.72,1.72,4.09,2.78,6.69,2.78h46.36 c2.6,0,4.97-1.07,6.69-2.78c1.72-1.72,2.78-4.09,2.78-6.69V102.29L68.31,102.29z M3,16.21h65.31v-3.74c0-2.6-1.07-4.97-2.78-6.69 C63.81,4.06,61.44,3,58.83,3H12.47C9.87,3,7.5,4.06,5.78,5.78C4.06,7.5,3,9.87,3,12.47V16.21L3,16.21z"/></g></svg>
                            <input
                                className='f-input'
                                type="phonenum"
                                id="phonenum"
                                name="phonenum"
                                value={formData.phonenum}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>

                    <div className='f-input-elemt'>
                        <label htmlFor="note">Additional Notes</label>
                        <div className='f-input-logo'>
                            <svg className='f-input-logo' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.71 122.88" height={22} width={20}><g><path d="M65.69,0.71C65.22,0.28,64.56,0,63.89,0c-0.14,0-0.28,0-0.43,0.05l-57.51,0c-1.61,0-3.12,0.66-4.21,1.75 C0.66,2.89,0,4.35,0,6.01v110.91c0,1.66,0.66,3.12,1.75,4.21c1.09,1.09,2.55,1.75,4.21,1.75c30.37,0,55.48,0,85.7,0 c1.66,0,3.12-0.66,4.21-1.75c1.09-1.09,1.75-2.55,1.75-4.21V34.76c0.05-0.24,0.09-0.43,0.09-0.66c0-0.8-0.38-1.51-0.9-2.03 L66.02,0.9c-0.09-0.09-0.14-0.14-0.24-0.19H65.69L65.69,0.71z M65.08,48.99c-0.62-0.58-1.33-0.89-2.13-0.84 c-0.8,0-1.51,0.31-2.09,0.93l-4.7,4.88L69.34,66.7l4.75-4.97c0.58-0.58,0.8-1.33,0.8-2.13c0-0.8-0.31-1.55-0.89-2.09L65.08,48.99 L65.08,48.99L65.08,48.99z M40.4,96.74c-1.73,0.58-3.51,1.11-5.24,1.69c-1.73,0.58-3.46,1.15-5.24,1.73 c-4.13,1.33-6.39,2.09-6.88,2.22c-0.49,0.13-0.18-1.77,0.84-5.77l3.28-12.56l0.27-0.28L40.4,96.74L40.4,96.74L40.4,96.74 L40.4,96.74z M32.24,78.78l20.35-21.13l13.18,12.69L45.2,91.74L32.24,78.78L32.24,78.78z M61.2,5.39v23.32 c0,2.22,0.9,4.26,2.36,5.72c1.47,1.47,3.5,2.36,5.72,2.36h22.89v80.12c0,0.14-0.05,0.33-0.19,0.43c-0.09,0.09-0.24,0.19-0.43,0.19 c-24.04,0-62.27,0-85.65,0c-0.14,0-0.33-0.05-0.43-0.19c-0.09-0.09-0.19-0.28-0.19-0.43V6.01c0-0.19,0.05-0.33,0.19-0.43 c0.09-0.09,0.24-0.19,0.43-0.19h55.24H61.2L61.2,5.39z M66.54,28.71V9.18l21.99,22.28H69.29c-0.76,0-1.42-0.33-1.94-0.8 C66.87,30.17,66.54,29.47,66.54,28.71L66.54,28.71L66.54,28.71z"/></g></svg>                        
                            <textarea
                                className='f-input f-input-note'
                                type="note"
                                id="note"
                                name="note"
                                value={formData.note}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>

                    <button type="submit">Submit</button> 
                </form>
            </div>
        </div>
  );
}

export default ContactForm;
