import React, { useState, useRef } from 'react';
import Select from 'react-select';
import ImageSlider from './imageslider';
import './stylesheets/propertyphotos.css';
import imageSets from './PropertyPhotosList';

/**
 * PropertyPhotos component
 * 
 * Displays a slider of property photos that can be filtered by different categories using a dropdown menu.
 */
function PropertyPhotos() {
  // Options for the image set dropdown
  const options = [
    { value: 'set_ls', label: 'Living Space' },
    { value: 'set_o', label: 'Outdoor Space' },
    { value: 'set_k', label: 'Kitchen' },
    { value: 'set_bd', label: 'Bedrooms' }
  ];

  // State to manage the currently selected image set
  const [currentImageSet, setCurrentImageSet] = useState(options[0]);

  // State to manage whether the dropdown menu is open
  const [menuOpen, setMenuOpen] = useState(false);

  // Ref for the dropdown select element
  const selectRef = useRef();

  /**
   * Handles a mouse click event on the dropdown button.
   *  
   * Focuses the dropdown select element when clicked.
   * 
   * @param {Object} event - The mouse click event
  **/
  const handleMouseClick = () => {
    selectRef.current.focus();
  };

  /**
   * Handles changes to the selected image set.
   * 
   * @param {Object} selectedOption - The newly selected option
   */
  const handleImageSetChange = (selectedOption) => {
    setCurrentImageSet(selectedOption);
  };

  // Custom styles for the react-select component
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#EDEDED',
      borderColor: '#007BFF',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#0056b3',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007BFF' : state.isFocused ? '#f0f0f0' : null,
      color: state.isSelected ? '#fff' : '#000',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#EDEDED',
    }),
  };

  return (
    <div className='prop-cnt'>
      <div className='slider-img-cnt'>
        {/* Image slider displaying the selected image set */}
        <ImageSlider images={imageSets[currentImageSet.value]} />
      </div>

      <div className='img-sel-cnt'>
        <div className='img-sel-btn'  onClick={handleMouseClick}>
          <Select 
            ref={selectRef}
            options={options} 
            onChange={handleImageSetChange} 
            value={currentImageSet} 
            styles={customStyles}
            menuIsOpen={menuOpen}
            onMenuOpen={() => setMenuOpen(true)}
            onMenuClose={() => setMenuOpen(false)}
            isSearchable={false}
          />
        </div>

        {/* Displaying text based on the selected image set */}
        {currentImageSet.value === 'set_ls' && (
          <div className='img-sel-txt'>
            Our great room offers modern and comfortable furnishings with two sitting areas, a flat screen TV, fully stocked eat in kitchen, as well as a wood burning fireplace.<br></br><br></br>We have games, puzzles, books and lots of separate corners for relaxing and enjoying the water views.
          </div>
        )}
        {currentImageSet.value === 'set_o' && (
          <div className='img-sel-txt'>
            With a long driveway from the street and 166 feet of waterfront, you have tons of privacy and your own unobstructed lake views. <br></br><br></br>Outside we also have many outdoor lounging areas and a screened porch for enjoying a meal sheltered from the elements. Parking onsite for 4+ cars.
          </div>
        )}
        {currentImageSet.value === 'set_k' && (
          <div className='img-sel-txt'>
            Our modern kitchen has all new appliances, including dual fuel 36" range and dishwasher, ice and water dispensing french door fridge and double farmhouse sink. <br></br><br></br>The kitchen is stocked with most equipment you would like to have, including traditional and Nespresso coffeemakers.
          </div>
        )}
        {currentImageSet.value === 'set_bd' && (
          <div className='img-sel-txt'>
            Property includes a master with king bed and ensuite bath, a queen bedroom and a bunk room with a single bed, double and trundle. Blackout drapes in bedrooms. <br></br><br></br>There is sleeping for up to 8 people in beds, assuming 6 adults and 2 children. All bedding, linens and towels are supplied and we even have full laundry facilities on site.
          </div>
        )}
      </div>
    </div>
  );
}

export default PropertyPhotos;