import React from 'react';
import { HashRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import AvailCalendar from './components/Calendar';
import ContactForm from './components/Contact-Form';
import PropertyPhotos from './components/PropertyPhotos';
import logo from './components/images/Archive/Blue sails 2-modified.png';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/**
 * Navigation component
 * 
 * Renders the navigation bar with links to different routes in the application.
 */
function Navigation() {
  return (
    <nav className="Nav-container">
      <img src={logo} alt='Blue Sails Lakeside Retreat Logo' className='logo-image'/>
      <div className="Nav-title">
        BLUE SAILS LAKESIDE RETREAT
      </div>
      <ul>
        <li>
          <NavLink to="/" activeClassName="active">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/calendar" activeClassName="active">
            Availability
          </NavLink>
        </li>
        <li>
          <NavLink to="/contact-form" activeClassName="active">
            Contact Us
          </NavLink>
        </li>
        <li>
          <NavLink to="/propertyphotos" activeClassName="active">
            Property Photos
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

function Footer() {
  return (
    <footer>
      <div className='f-cnt'>
        <div className='f-gen-txt'> 
          Copyright © 2024 Blue Sails Retreat Lakeside - All Rights Reserved.
        </div>
        <ul>
          <li>
            <NavLink to="/" activeClassName="active">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/calendar" activeClassName="active">
              Availability
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact-form" activeClassName="active">
              Contact Us
            </NavLink>
          </li>
          <li>
            <NavLink to="/propertyphotos" activeClassName="active">
              Property Photos
            </NavLink>
          </li>
        </ul>
      </div>
    </footer>
  );
}

/**
 * App component
 * 
 * Main application component that sets up the router and routes.
 */
function App() {
  return (
    <Router>
      <div className="App">
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/calendar" element={<AvailCalendar />} />
          <Route path="/contact-form" element={<ContactForm />} />
          <Route path="/propertyphotos" element={<PropertyPhotos />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
